import type_script from "../../../assets/technologies/type_script.png";
import java_script from "../../../assets/technologies/java_script.png";
import html5 from "../../../assets/technologies/html.png";
import css3 from "../../../assets/technologies/css.png";
import react from "../../../assets/technologies/react.png";
import redux from "../../../assets/technologies/redux.png";
import redux_saga from "../../../assets/technologies/redux_saga.png";
import styled_components from "../../../assets/technologies/styled_components.png";
import react_router from "../../../assets/technologies/react_router.png";
import figma from "../../../assets/technologies/figma.png";
import axios from "../../../assets/technologies/axios.png";
import github from "../../../assets/technologies/github.png";
import npm from "../../../assets/technologies/npm.png";
import tailwind from "../../../assets/technologies/tailwind.png";
import vite from "../../../assets/technologies/vite.png";
import create_react_app from "../../../assets/technologies/create_react_app.png";
import make from "../../../assets/technologies/make.png";
import mongoDB from "../../../assets/technologies/mongoDB.png";
import nodeJS from "../../../assets/technologies/nodejs.png";
import nextJS from "../../../assets/technologies/nextjs.png";
import cypress from "../../../assets/technologies/cypress.png";
import jest from "../../../assets/technologies/jest.png";
import hono from "../../../assets/technologies/honojs.png";
import express from "../../../assets/technologies/expressjs.png";
import tanstack_query from "../../../assets/technologies/tanstack_query.png";
import framer_motion from "../../../assets/technologies/framer_motion.png";
import { Technology } from "../../../types/types";

export const technologies: Technology[] = [
  {
    name: "TypeScript",
    icon: type_script,
  },
  {
    name: "JavaScript ES6+",
    icon: java_script,
  },
  {
    name: "HTML5",
    icon: html5,
  },
  {
    name: "CSS3",
    icon: css3,
  },
  {
    name: "React",
    icon: react,
  },
  {
    name: "Redux",
    icon: redux,
  },
  {
    name: "Redux Saga",
    icon: redux_saga,
  },
  {
    name: "Styled Components",
    icon: styled_components,
  },
  {
    name: "React Router",
    icon: react_router,
  },
  {
    name: "Figma",
    icon: figma,
  },
  {
    name: "Axios",
    icon: axios,
  },
  {
    name: "GitHub",
    icon: github,
  },
  {
    name: "GitHub API",
    icon: github,
  },
  {
    name: "NPM",
    icon: npm,
  },
  {
    name: "OpenAI",
    icon: null,
  },
  {
    name: "Tailwind CSS",
    icon: tailwind,
  },
  {
    name: "Vite",
    icon: vite,
  },
  {
    name: "Create React App",
    icon: create_react_app,
  },
  {
    name: "Make.com",
    icon: make,
  },
  {
    name: "TanStack Query",
    icon: tanstack_query,
  },
  {
    name: "Framer Motion",
    icon: framer_motion,
  },
  {
    name: "MongoDB",
    icon: mongoDB,
  },
  {
    name: "Node.js",
    icon: nodeJS,
  },
  {
    name: "Next.js",
    icon: nextJS,
  },
  {
    name: "Cypress",
    icon: cypress,
  },
  {
    name: "Jest",
    icon: jest,
  },
  {
    name: "Hono.js",
    icon: hono,
  },
  {
    name: "Express.js",
    icon: express,
  },
];
